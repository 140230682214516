import {useBreakpointValue} from '@chakra-ui/react';
import {GoogleAd} from './GoogleAd';

export function GoogleBannerAd() {
  const isMobile = useBreakpointValue([true, true, false]);

  return isMobile ? (
    <GoogleAd slotName="/22892919920/EKSAB-MobileLeaderboard" />
  ) : (
    <GoogleAd slotName="/22892919920/EKSAB-Leaderboard" />
  );
}
