import {create} from 'zustand';

type State = {
  definedSlots: {[key: string]: boolean};
  addSlot(slotName: string): void;
  isSlotDefined(slotName: string): boolean;
  hasAnySlotDefined(): boolean;
};

const initialState: Pick<State, 'definedSlots'> = {
  definedSlots: {},
};

export const useGoogleAdStore = create<State>((set, get) => ({
  ...initialState,
  addSlot: (slotName: string) => set((state) => ({definedSlots: {...state.definedSlots, [slotName]: true}})),
  isSlotDefined: (slotName: string) => !!get().definedSlots[slotName],
  hasAnySlotDefined: () => Object.keys(get().definedSlots).length > 0,
}));
