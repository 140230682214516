import {GoogleAdSlot, GoogleAdSlotName} from './types';

export const GOOGLE_AD_SLOTS: Record<GoogleAdSlotName, GoogleAdSlot> = {
  '/22892919920/EKSAB-MobileLeaderboard': {
    size: [
      [320, 100],
      [320, 50],
    ],
    placementElementId: 'div-gpt-ad-1738580484432-0',
  },
  '/22892919920/EKSAB-Leaderboard': {
    size: [728, 90],
    placementElementId: 'div-gpt-ad-1738580420261-0',
  },
};
