import {useEffect} from 'react';
import {Flex} from '@chakra-ui/react';
import {useGoogleAdStore} from './useGoogleAdStore';
import {GOOGLE_AD_SLOTS} from './const';

type GoogleAdProps = {
  slotName: string;
};

export function GoogleAd({slotName}: GoogleAdProps) {
  const store = useGoogleAdStore();

  const slot = GOOGLE_AD_SLOTS[slotName];

  useEffect(() => {
    if (typeof window !== 'undefined' && window.googletag && window.googletag.cmd) {
      const {googletag} = window;

      if (!store.isSlotDefined(slotName)) {
        googletag.cmd.push(function () {
          googletag.defineSlot(slotName, slot.size, slot.placementElementId).addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.pubads().collapseEmptyDivs();
          googletag.enableServices();
        });

        store.addSlot(slotName);
      }

      googletag.cmd.push(function () {
        googletag.display(slot.placementElementId);
      });
    }
  }, [slotName]);

  const firstSlotSize: [number, number] = Array.isArray(slot.size[0]) ? slot.size[0] : (slot.size as [number, number]);

  return (
    <Flex
      justifyContent="center"
      mb="5"
      id={slot.placementElementId}
      style={{minWidth: firstSlotSize[0], minHeight: firstSlotSize[1]}}
    />
  );
}
